import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { graphql, navigate } from 'gatsby';
import React from 'react';
import LoadQuoteWrapper from 'components/ApiRequestWrapper/LoadQuoteWrapper';
import Layout from 'components/Layout';
import AdditionalQuestionsForm from 'forms/AdditionalQuestionsForm';
import { useCheckoutTracking } from 'helpers/ecommerceTracking';
import { PageTitle } from 'helpers/eventTracking';
import { usePageTracking } from 'helpers/pageTracking';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersPlainText } from 'helpers/placeholders/replaceCsPlaceholders';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import useAggregatorBrandDetails from 'helpers/useAggregatorBrandDetails';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import {
  HeadingWrapper,
  LargeRichTextWithModal,
  QuestionHeading,
  StyledImage,
} from './styles';

type AdditionalQuestionsProps = {
  data: {
    csPetAboutYourPet: {
      next_button_text: string;
    };
    csPetAggregatorsV2: {
      additional_questions: {
        meta_title: string;
        thank_you_text: string;
        heading: string;
        intro_text: string;
      };
    };
  };
};

export const query = graphql`
  query {
    csPetAggregatorsV2 {
      additional_questions {
        meta_title
        thank_you_text
        heading
        intro_text
      }
    }
  }
`;

const AdditionalQuestions: React.FC<AdditionalQuestionsProps> = ({
  data: {
    csPetAggregatorsV2: {
      additional_questions: { meta_title, heading, intro_text: csIntro },
    },
  },
}) => {
  const PAGE_LANDING_STEP = 4;
  const quote = useCurrentQuote();
  usePageTracking(meta_title, !!quote.customerInfo);
  useCheckoutTracking(PAGE_LANDING_STEP, quote, true);

  const moveNext = (): void => {
    navigate(quoteAndBuyRoutes.loadingQuote, {
      state: { shouldNotInvalidateAssumptions: true },
    });
  };

  const aggregatorBranding = useAggregatorBrandDetails(quote.productId);

  const intro_text = replacePlaceholdersPlainText(
    { petNames: quotePlaceholders.petNames },
    {
      ...quote,
      petInfos: quote.petInfos,
    },
    true
  )(csIntro);

  return (
    <LoadQuoteWrapper>
      <Layout metaTitle={meta_title} pageTitle={PageTitle.AdditionalQuestions}>
        <Grid>
          <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
            <HeadingWrapper>
              <QuestionHeading>{heading}</QuestionHeading>
              <LargeRichTextWithModal
                pageTitle={PageTitle.AdditionalQuestions}
                html={intro_text}
              />
              {aggregatorBranding?.logo && (
                <StyledImage image={aggregatorBranding.logo} />
              )}
            </HeadingWrapper>
            <AdditionalQuestionsForm moveNext={moveNext} />
          </GridItem>
        </Grid>
      </Layout>
    </LoadQuoteWrapper>
  );
};

export default AdditionalQuestions;
