import Image from '@rsa-digital/evo-shared-components/components/Image';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledImage = styled(Image)`
  text-align: center;
  height: ${spacing(4)};
  width: auto;
`;

export const QuestionHeading = styled.h1`
  ${fonts.headingLarge}
  text-align: center;
  margin: ${spacing(6)} 0 ${spacing(2)};
`;

export const LargeRichTextWithModal = styled(RichTextWithModal)`
  margin-bottom: ${spacing(2)};
  text-align: center;
  p {
    ${fonts.paragraphLarge};
  }
`;
