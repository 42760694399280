import { Image } from '@rsa-digital/evo-shared-components/commonTypes/image';
import { graphql, useStaticQuery } from 'gatsby';
import { CsAsset } from 'types/contentStack';
import { ProductId } from './businessConstants';
import { processOptionalImageAsset } from './csTypeProcessors';

type AggregatorBrandDetails = {
  logo: Image | undefined;
  displayName: string;
};

type CsAggregatorBrandDetails = {
  csPetAggregatorsV2: {
    go_compare: {
      logo: CsAsset;
      display_name: string;
    };
    compare_the_market: {
      logo: CsAsset;
      display_name: string;
    };
    money_super_market: {
      logo: CsAsset;
      display_name: string;
    };
    confused: {
      logo: CsAsset;
      display_name: string;
    };
  };
};

const query = graphql`
  query {
    csPetAggregatorsV2 {
      go_compare {
        logo {
          ...CsAsset
        }
        display_name
      }
      compare_the_market {
        logo {
          ...CsAsset
        }
        display_name
      }
      money_super_market {
        logo {
          ...CsAsset
        }
        display_name
      }
      confused {
        logo {
          ...CsAsset
        }
        display_name
      }
    }
  }
`;

const useAggregatorBrandDetails = (
  aggregator: ProductId
): AggregatorBrandDetails | null => {
  const content = useStaticQuery<CsAggregatorBrandDetails>(query);

  const getAggregatorBrandDetails = (): {
    logo: CsAsset;
    display_name: string;
  } | null => {
    switch (aggregator) {
      case ProductId.COMPARE_THE_MARKET:
        return content.csPetAggregatorsV2.compare_the_market;
      case ProductId.GO_COMPARE:
        return content.csPetAggregatorsV2.go_compare;
      case ProductId.MONEY_SUPERMARKET:
        return content.csPetAggregatorsV2.money_super_market;
      case ProductId.CONFUSED:
        return content.csPetAggregatorsV2.confused;
      default:
        return null;
    }
  };

  const aggregatorBrandDetails = getAggregatorBrandDetails();

  return aggregatorBrandDetails
    ? {
        logo: processOptionalImageAsset(aggregatorBrandDetails.logo),
        displayName: aggregatorBrandDetails.display_name,
      }
    : null;
};

export default useAggregatorBrandDetails;
