import DateInput from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import {
  addLeadingZerosToDateValue,
  parseDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import useValidationWithWarnings from '@rsa-digital/evo-shared-components/helpers/forms/useValidationWithWarnings';
import {
  isConfusedQuote,
  isCTMQuote,
  isDog,
  petAgeIsEqualToTwoMonths,
  shouldAggsQuoteShowAdditionalQuestions,
} from 'businessLogic/aggregators';
import {
  getYoungestPetAgeThresholdDate,
  useFirstEligibleStartDateFromToday,
} from 'businessLogic/petAge';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import FormFooter from 'components/FormFooter';
import DogBreedQuestions from 'forms/AboutYourPetsForm/AboutYourPet/DogBreedQuestions';
import { usePetDetailsOptions } from 'forms/AboutYourPetsForm/usePetsDetailsOptions';
import SectionHeading from 'forms/SectionHeading';
import { updateItem } from 'helpers/arrayHelpers';
import {
  PageTitle,
  trackFieldError,
  trackFormTextFieldFocus,
  trackTextButtonClick,
} from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import { getAnalyticsDescriptionForInput } from 'helpers/getAnalyticsDescriptionForInput';
import getPetIdForInput from 'helpers/getPetId';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { CurrentQuote, useCurrentQuote } from 'helpers/useCurrentQuote';
import { useAdditionalQuestionsShown } from 'state/formData/additionalQuestionsShown';
import { Pet, PetsDetails, PetWithKey, usePetsDetails } from 'state/formData/petsDetails';
import { usePolicyDetails } from 'state/formData/policyDetails';
import useReferenceData from 'state/referenceData/useReferenceData';
import useAdditionalQuestionsPageQuestions from './questions';
import {
  CoverDateDescription,
  CoverStartSubheading,
  StyledAgeQuestion,
  StyledCoverStartDateQuestion,
} from './styles';
import useAdditionalQuestionsRules from './validation';

type AdditionalQuestionsFormData = {
  csPetAboutYourPetV2: {
    next_button_text: string;
  };
  csPetAggregatorsV2: {
    additional_questions: {
      cover_start_date_subheading: string;
      cover_start_date_description: string;
    };
  };
};

export const query = graphql`
  query {
    csPetAboutYourPetV2 {
      next_button_text
    }
    csPetAggregatorsV2 {
      additional_questions {
        cover_start_date_subheading
        cover_start_date_description
      }
    }
  }
`;

type AdditionalQuestionsFormProps = {
  moveNext: () => void;
};

const AdditionalQuestionsForm: React.FC<AdditionalQuestionsFormProps> = ({
  moveNext,
}) => {
  const {
    csPetAboutYourPetV2: { next_button_text },
    csPetAggregatorsV2: { additional_questions },
  } = useStaticQuery<AdditionalQuestionsFormData>(query);

  const [
    additionalQuestionsShown,
    updateAdditionalQuestionsShown,
  ] = useAdditionalQuestionsShown();
  const [policyDetails, updatePolicyDetails] = usePolicyDetails();
  const dogBreedsRefData = useReferenceData('dogBreeds')?.dogBreeds ?? [];
  const quote = useCurrentQuote();
  const [initialQuote] = useState(quote);
  const [firstEligibleStartDate] = useFirstEligibleStartDateFromToday();

  const updateCoverStartDate = (pets: Pet[]): Date => {
    const thresholdDate = getYoungestPetAgeThresholdDate(pets);
    if (thresholdDate && thresholdDate > new Date(policyDetails.coverStartDate)) {
      return firstEligibleStartDate;
    }
    return new Date(policyDetails.coverStartDate);
  };

  const [hasShownAgeQuestion, setHasShownAgeQuestion] = useState(new Set());

  const shouldShowAgeQuestion = (
    dateOfBirth: Date | undefined,
    index: number
  ): boolean => {
    if (petAgeIsEqualToTwoMonths(dateOfBirth) || hasShownAgeQuestion.has(index)) {
      if (!hasShownAgeQuestion.has(index)) {
        setHasShownAgeQuestion(hasShownAgeQuestion.add(index));
        updateAdditionalQuestionsShown({
          ageQuestion: [...(additionalQuestionsShown.ageQuestion ?? []), index],
        });
      }
      return true;
    }
    return false;
  };

  const shouldShowBreedQuestion = (
    currentQuote: CurrentQuote,
    index: number
  ): boolean => {
    if (
      currentQuote.petInfos &&
      !isCTMQuote(quote) &&
      isConfusedQuote(quote) &&
      isDog(currentQuote.petInfos[index])
    ) {
      if (!additionalQuestionsShown.breedQuestion?.includes(index)) {
        updateAdditionalQuestionsShown({
          breedQuestion: [...(additionalQuestionsShown.breedQuestion ?? []), index],
        });
      }
      return true;
    }
    return false;
  };

  const [petsDetails, updatePetsDetails] = usePetsDetails();
  const petRules = useAdditionalQuestionsRules();

  // Currently we only route to this page under certain conditions after generating a quote on CTM.
  // CTM only allows for one pet to be added on the quote. If this changes in future the logic will
  // have to be amended
  const questions = useAdditionalQuestionsPageQuestions(petsDetails[0]);

  const {
    getError,
    getWarning,
    showValidation,
    validateOnSubmit,
  } = useValidationWithWarnings<{ petsDetails: PetsDetails }>(
    { petsDetails },
    petRules.errors,
    petRules.warnings,
    trackFieldError
  );

  const [hasShownCalendar, setHasShownCalendar] = useState(new Set());

  const shouldShowCalendar = (dateOfBirth: Date, index: number): boolean => {
    if (
      (!!getWarning('petsDetails', ['petDob', index]) &&
        !getError('petsDetails', ['petDob', index])) ||
      hasShownCalendar.has(index)
    ) {
      if (!hasShownCalendar.has(index))
        setHasShownCalendar(hasShownAgeQuestion.add(index));
      return true;
    }
    return false;
  };

  const { petSectionHeading } = usePetDetailsOptions();

  const generateSectionLabel = (petIndex: number): string => {
    const { petName } = petsDetails[petIndex];
    return petNameReplacer(petName, petSectionHeading);
  };

  return (
    <form onSubmit={validateOnSubmit(moveNext, scrollAndTrackError)}>
      {petsDetails.map(
        (petDetails, index) =>
          quote.petInfos &&
          shouldAggsQuoteShowAdditionalQuestions(quote) && (
            <div id={`petsDetails[${index}]`}>
              <SectionHeading heading={generateSectionLabel(index)} />
              {quote.petInfos &&
                (shouldShowAgeQuestion(parseDate(quote.petInfos[index].dob), index) ??
                  undefined) && (
                  <>
                    <StyledAgeQuestion
                      question={questions.petDob}
                      errorText={getError('petsDetails', ['petDob', index])}
                      warningText={getWarning('petsDetails', ['petDob', index])}
                      maskQuestionData>
                      <DateInput
                        id={getPetIdForInput(index)('petDob')}
                        value={petDetails.petDob}
                        onChange={(value) => {
                          updatePetsDetails(
                            updateItem(petsDetails, index, { petDob: value })
                          );
                          updatePolicyDetails({
                            coverStartDate: updateCoverStartDate([
                              petDetails,
                            ]).toISOString(),
                          });
                          showValidation('petsDetails', ['petDob', index]);
                        }}
                        onBlur={() => {
                          updatePetsDetails(
                            updateItem(petsDetails, index, {
                              petDob: addLeadingZerosToDateValue(petDetails.petDob),
                            })
                          );
                          updatePolicyDetails({
                            coverStartDate: updateCoverStartDate([
                              petDetails,
                            ]).toISOString(),
                          });
                          showValidation('petsDetails', ['petDob', index]);
                          trackFormTextFieldFocus(
                            getAnalyticsDescriptionForInput(index, 'Pet dob')
                          );
                        }}
                        onFocus={trackFormTextFieldFocus(
                          getAnalyticsDescriptionForInput(index, 'Pet dob')
                        )}
                      />
                    </StyledAgeQuestion>
                    <>
                      {shouldShowCalendar(
                        parseDate(quote.petInfos[index].dob),
                        index
                      ) && (
                        <>
                          <CoverStartSubheading>
                            {additional_questions.cover_start_date_subheading}
                          </CoverStartSubheading>
                          {!!getWarning('petsDetails', ['petDob', index]) && (
                            <CoverDateDescription>
                              {additional_questions.cover_start_date_description}
                            </CoverDateDescription>
                          )}
                          <StyledCoverStartDateQuestion
                            minDate={firstEligibleStartDate}
                            disableCalendar={!!getError('petsDetails', ['petDob', index])}
                          />
                        </>
                      )}
                    </>
                  </>
                )}
              {shouldShowBreedQuestion(initialQuote, index) && (
                <DogBreedQuestions
                  index={index}
                  petDetails={petDetails}
                  updatePetDetails={(update: Partial<PetWithKey>) =>
                    updatePetsDetails(updateItem<PetWithKey>(petsDetails, index, update))
                  }
                  formValidation={{ getError, showValidation }}
                  dogBreedReferenceData={dogBreedsRefData}
                  pageTitle={PageTitle.AdditionalQuestions}
                />
              )}
              <FormFooter
                contentColumns={{ desktop: 6, tabletLandscape: 6 }}
                moveNextButton={{
                  text: next_button_text,
                  onClick: () =>
                    trackTextButtonClick(PageTitle.AdditionalQuestions, next_button_text),
                }}
                pageTitle={PageTitle.AdditionalQuestions}
              />
            </div>
          )
      )}
    </form>
  );
};

export default AdditionalQuestionsForm;
